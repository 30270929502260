<template>
  <Dialog :commonDialog="dialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      <v-layout>
        <v-flex> Manage Team Member</v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 90vh; position: relative"
      >
        <v-container class="py-0">
          <v-row class="py-0">
            <v-col md="12" class="py-0">
              <table class="width-100">
                <tbody>
                  <tr>
                    <td width="50%">
                      <label class="font-weight-700 font-size-16">
                        Enter Team Name</label
                      >
                      <v-text-field
                        v-model.trim="teamManage.team_name"
                        dense
                        filled
                        label="Enter Team Name"
                        solo
                        flat
                        class="width-100"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50%">
                      <template>
                        <label class="font-weight-700 font-size-16">
                          Select Technicians</label
                        >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          id="job-type"
                          :items="engineerList"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          label="Select Technicians"
                          solo
                          flat
                          item-color="cyan"
                          v-model.trim="teamManage.staffData"
                          placeholder="Select Technicians"
                          item-text="display_name"
                          :item-value="(row) => row.id"
                          hide-details
                          multiple
                          return-object
                          class="width-100"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Technicians Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                          <template v-slot:value>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Technicians Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </template>
                    </td>
                    <!--  <td width="50%">
                      <template>
                        <label class="font-weight-700 font-size-16">
                       
                          Select Supervisor</label
                        >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          id="job-type"
                          :items="supervisorList"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          label="Select Supervisor"
                          solo
                          flat
                          item-color="cyan"
                          v-model.trim="teamManage.supervisor"
                          placeholder="Select Supervisor"
                          item-text="display_name"
                          :item-value="(row) => row.id"
                          hide-details
                          return-object
                          multiple
                          
                          class="width-100"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Supervisor Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                          <template v-slot:value>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Supervisor Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </template>
                    </td> -->
                  </tr>

                  <!-- <tr >
                    <td width="50%">
                      <v-layout>
                        <v-flex>
                          <v-switch
                            v-model="teamManage.notification"
                            class="mt-0"
                            inset
                            label="Manage Team Notification"
                            color="cyan"
                          ></v-switch>
                        </v-flex>
                      </v-layout>
                    </td>
                    <td width="50%">
                      <template>
                        <label class="font-weight-700 font-size-16">
                          Select Member</label
                        >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          id="job-type"
                          :items="engineerList"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          label="Select Team"
                          solo
                          flat
                          item-color="cyan"
                          v-model.trim="teamManage.staffData"
                          placeholder="Select Staff"
                          item-text="display_name"
                          :item-value="(row) => row.id"
                          hide-details
                          multiple
                          return-object
                          class="width-100"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Member Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                          <template v-slot:value>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Member Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </template>
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
    </template>
    <template v-slot:action>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateorcreateteams"
      >
        Save
      </v-btn>
      <!-- <v-btn
        :disabled="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('resetAll', true)"
      > -->
      <v-btn
        :disabled="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="CloseLeave"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import { QUERY } from "@/core/services/store/request.module";
import { PUT, POST } from "../../../core/services/store/request.module";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    /*   teamlistUrl: {
      type: Boolean,
      required: true,
      default: false,
    },
 */
    updatePopup: {
      type: Array,
      default() {
        return new Object();
      },
    },
    manageBrandDialog: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    updatePopup: {
      deep: true,
      immediate: true,
      handler(param) {
        this.init(param);
      },
    },
  },
  data: () => {
    return {
      teamManage: {},
      display_name: [],
      notification: 0,
      team_name: null,
      pageLoading: false,
      engineerList: [],
      supervisorList: [],
    };
  },
  methods: {
    init(data) {
      this.teamManage = {
        id: data.id,
        team_name: data.team_name,
        // supervisor: { id: data.supervisor },
        notification: data.notification,
        staffData: data.staffData,
        supervisor: data.supervisorData,
      };
      //this.get_supervisor_list(this.teamManage);
      this.get_staff_list(this.teamManage);
    },
    get_staff_list() {
      const _this = this;

      _this.$store
        .dispatch(QUERY, {
          url: "engineer-list",
          data: { status: "all", team_id: this.teamManage.id },
        })
        .then(({ data }) => {
          _this.engineerList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },

    /* get_supervisor_list() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "engineer-role-list",
          data: { status: "all", team_id: this.teamManage.id },
        })
        .then(({ data }) => {
          _this.supervisorList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    }, */
    updateorcreateteams() {
      if (this.teamManage.id) {
        this.updateTeamCase(this.teamManage);
      } else {
        this.createTeamCase();
      }
    },

    CloseLeave() {
      this.teamManage.team_name = "";
      this.teamManage.staffData = "";
      this.teamManage.supervisor = "";

      // this.teamManage.supervisor = "";
      this.teamManage.notification = "";
      this.$emit("close", true);
    },
    createTeamCase() {
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "team",
          data: {
            team_name: _this.teamManage.team_name,
            all_staff: _this.teamManage.staffData,
            supervisor_id: _this.teamManage.supervisor,

            //  supervisor: _this.teamManage.supervisor.id,
            notification: _this.teamManage.notification,
          },
        })
        .then(() => {
          this.$emit("close", true);
          this.teamManage.team_name = "";
          this.teamManage.staffData = "";
          this.teamManage.supervisor = "";
          //   this.teamManage.supervisor = "";
          this.teamManage.notification = "";
          this.$emit("refresh", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateTeamCase(teamManage) {
      const _this = this;
      _this.$store
        .dispatch(PUT, {
          url: "team/" + teamManage.id,
          data: {
            team_name: _this.teamManage.team_name,
            all_staff: _this.teamManage.staffData,
            supervisor_id: _this.teamManage.supervisor,
            // supervisor: _this.teamManage.supervisor.id,
            notification: _this.teamManage.notification,
          },
        })
        .then(() => {
          this.teamManage.team_name = "";
          this.teamManage.staffData = "";
          this.teamManage.supervisor = "";
          //  this.teamManage.supervisor = "";
          this.teamManage.notification = "";
          this.$emit("close", true);
          this.$emit("refresh", true);
          //console.log(data);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    Dialog,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
  mounted: function () {
    //this.get_supervisor_list();
  },
};
</script>
